@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Mr+Dafoe&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Noto+Sans&family=Oswald:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Josefin+Sans&family=Noto+Sans+JP&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Poppins', sans-serif;
   */
   font-family: 'Noto Sans', sans-serif;
}
*{
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Noto Sans', sans-serif; */
  font-family: 'Josefin Sans', sans-serif;
}
body, html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.PhoneInputInput{
  background-color: #FFEDF1;
  border-radius: 5px;
  height: 2.5rem;
  outline: none;
  padding-left: 1rem;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-zoomFadeIn {
  animation: zoomFadeIn 1s ease-in-out;
}

.with-box-shadow {
  position: relative; 
  box-shadow: inset 5px 0px 5px -5px #bdbdbd, inset -5px 0px 5px -5px #bdbdbd;
  z-index: 1;
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  /* products filter animation icon rotating */
 /* Clockwise rotation */
.rotate-clockwise {
  transition: transform 0.3s ease-in-out;
}

.rotate-clockwise.rotate {
  transform: rotate(180deg);
}

/* Counterclockwise rotation */
.rotate-counterclockwise {
  transition: transform 0.3s ease-in-out;
}

.rotate-counterclockwise.rotate {
  transform: rotate(0deg);
}

/* For WebKit (Chrome, Safari) */
.scrollbar-hide::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}