.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust as needed */
}

.loader-image {
  animation: rotate 2s linear infinite; /* Adjust animation duration as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
